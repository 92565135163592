.main-content {
  position: relative;
}

.navprofile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  padding: 14px 18px;
  background-color: white;

  .nav-profile {    
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;      
    }
    span {
      display: inline-block;
      margin-left: 10px;
      font-weight: 300;
      font-size: 16px;
      b {
        font-weight: 700;
      }
    }
  }

  .notif-area {
    button {
      background-color: transparent;
      border: 0;
      padding: 0;
    }
    button::before{
      content: unset;
    }
    button::after{
      content: unset;
    }

    .dropdown-menu.show {
      top: 30px;
      min-width: 300px;
      max-width: 300px;
      inset: 30px 0px auto auto!important;
      right: 0;
      top: 27px;
      transform: unset!important;
      padding: 0;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }

  .nav-notif {
    position: relative;

    .badge.badge-danger {
      position: absolute;
      top: 0;
      right: -5px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 10px;
    }
  }

  .notif-list::-webkit-scrollbar {
    width: 4px;
  }

  .notif-list::-webkit-scrollbar-track {
    background: #bebebe;
  }

  .notif-list::-webkit-scrollbar-thumb {
    background-color: #838383;
    border-radius: 10px;
    border: 3px solid #838383;
    width: 5px;
    background-clip: content-box;
  }
}

.notif-list {
  // padding: 14px;
  max-height: 250px;
  overflow: auto;

  .notif-item:last-child{
    border: unset;
  }
  .notif-item.read {
    background-color: transparent;
  }
  .notif-item {
    border-bottom: 1px solid #dbdbdb;
    padding: 10px 20px;
    cursor: pointer;
    // padding: 10px 0;
    background-color: #ebebeb;

    .notif-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Open Sans', sans-serif;
      .notif-title {
        font-weight: 700;
        font-size: 13px;
        margin-right: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }

      .notif-time {
        font-weight: 500;
        font-size: 11px;
        color: #686464;
      }
    }

    .notif-body {
      margin-top: 5px;
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      p {
        margin-bottom: 0;
      }
    }

  }

  .showmore-notif {
    text-align: center;
    padding: 10px;
    background: #ca2d2d;
    color: white;
    font-size: 13px;
    cursor: pointer;
  }

  .showmore-notif:hover {
    background: #bc1c1c;
  }
}

.notif-list.notif-page {
  max-height: unset;

  .notif-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .notif-header .notif-title {
    font-size: 14px;
  }

  .notif-body {
    margin-top: 10px;
    font-size: 13px;
  }
}

.card.card-danger {
  background-color: #CA2D2D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  color: white;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;

  .card-body {
    border: none;
    background-color: #CA2D2D;
    border-radius: 5px;
    padding: 20px;

    h4 {
      font-weight: 700;
      font-size: 17px;
      padding-left: 10px;
    }

    .list-payment::-webkit-scrollbar {
      width: 4px;
    }
  
    .list-payment::-webkit-scrollbar-track {
      background: #838383;
    }
  
    .list-payment::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 10px;
      border: 3px solid #ffffff;
      width: 5px;
      background-clip: content-box;
    }

    ul.list-payment {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 150px;
      direction: rtl;
      padding: 0;

      li {
        direction:ltr;
        list-style: none;
        padding-left: 15px;
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}

.payment-card {
  min-width: 200px;
  width: 250px;
  .payment-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFE600;
    color: black;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 39px;
    display: flex;
    align-items: center;
    line-height: 15px;
  }

  .payment-value {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px 15px;
    color: black;
    text-align: left;
    font-weight: bold; 
    min-height: 55px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
}

.payment-button{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  font-size: 12px;
  // width: 100px;
  button {
    background-color: white;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 4px;
    margin-bottom: 5px;
  } 
  button:hover {
    background-color: rgb(215, 215, 215);
  }
  span {
    display: block;
    width: 100%;
    font-weight: bold;
  }
}

.program-list-wrapper {
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: 'Roboto', sans-serif;
  h4 {
    font-weight: bold;
    font-size: 15px;
  }

  .program-list-container {

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1.5rem;

    .program-list-item {
      margin-right: 13px;
      margin-bottom: 1rem;

      .program-image {
        background-color: white;
        width: 121px;
        height: 121px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        border-radius: 10px;

        img {
          width: 85%;
          height: auto;
        }
      }
    
      span {
        width: 100%;
        display: block;
        font-weight: 500;
        text-align: center;
        padding-top: 7px;
        max-width: 121px;
        font-size: 14px;
      }
    }

    .owl-theme .owl-dots .owl-dot span {
      border-radius: unset;
      height: 2px;
      width: 20px;
    }

    .owl-theme .owl-dots .owl-dot.active span {
      height: 3px;
    }
  }
}

.registered-wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .registered-container {
    .registered-item {
      background-color: #e5e5e5;
      border-radius: 5px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 50px;
      .registered-item-header {
        padding: 10px;
        position: relative;

        .registered-item-image {
          position: absolute;
          top: -20px;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          padding: 8px;
          border-radius: 50%;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }
        }

        span {
          display: block;
          padding-left: 67px;
          font-size: 13px;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 15px;
          height: 32px;
        }
      }

      .registerd-progress {
        padding: 10px 0;
        margin: 10px 20px;
        position: relative;
        .registerd-progress-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 25px 0 0;

          .progress-item {
            z-index: 3;
            max-width: 50px;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            text-align: center;

            .progress-icon {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background-color: #888888;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              svg {
                path {
                  stroke: #C4C4C4;
                }
              }
            }

            span{
              font-size: 12px;
              line-height: 15px;
              display: block;
              margin-top: 10px;
              height: 30px;
            }
          }

        }

        .progress-line::after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background-color: #CA2D2D;
          position: absolute;
          top: 31%;
          // transform: translateY(-50%);
        }

        .progress-item.active {
          .progress-icon {
            background-color: #FFE600;
            svg {
              path {
                stroke: #CA2D2D;
              }
            }
          }
        }
      }

      .registered-warning {
        padding: 10px;
        margin: 0 20px;
        text-align: center;
        .alert.alert-danger {
          background-color: #CA2D2D;
          border-radius: 25px;
        }

        .alert.alert-success {
          background-color: #259413;
        }
        .alert {
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 10px;

          .alert-text {
            text-transform: uppercase;
            margin-left: 5px;
            font-size: 13px;
          }
          .alert-text.bold{ 
            font-weight: 700;
          }
        }
        .registered-info {
          font-size: 12px;
          font-weight: normal;
          text-transform: capitalize;
        }
      }

      .registered-button {
        background-color: #2D92CA;
        text-transform: uppercase;
        text-align: center;
        color: white;
        font-size: 14px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor: pointer;
        button {
          padding: 13px;
          width: 100%;
          background-color: transparent;
          color: white;
          font-size: 14px;
          border:0;
          text-transform: uppercase;
        }
      }
      .registered-button:hover {
        background-color: #2483b5;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.btn.btn-program{
  background-color: #CA2D2D;
  font-weight: 700;
  color: white;
  font-size: 14px;
  padding: 8px 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn.btn-program:hover {
  background-color: #b52c27;
  color: white;
}

.btn.btn-program-orange {
  background-color: #ffb904;
  color: black;
  padding: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn.btn-program-orange:hover {
  background-color: #dfa208;
}

.btn.btn-claim {
  background-color: #ffb904;
  color: white;
  padding: 6px;
  font-size: 14px;
  min-width: 100px;
  font-weight: bold;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn.btn-claim:hover {
  background-color: #dfa208;
  color: white;
}

.btn.btn-whatsapp {
  background-color: #08431C;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  img {
    margin-right: 5px;
  }
}

.btn.btn-whatsapp:hover {
  background-color: #093618;
  color: white;
}


.menu-program {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px 12px 0;
    border-bottom: 1px solid #bdbdbd;
    font-size: 15px;
    .dropdown {
      .dropdown-toggle {
        background: transparent;
        color: black;
        padding: 0;
        border: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: none;
        svg {
          transition: transform 0.2s ease-in-out;
        }
      }
      .dropdown-toggle:hover {
        outline: none;
      }
      .dropdown-toggle:active {
        background-color: transparent;
        color: black;
        outline: none;
      }

      .dropdown-toggle::after {
        content: none;
      }

      .dropdown-menu.show {
        width: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px 3px 20px 20px;
        padding: 0px 25px 25px 25px;
        .dropdown-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 20px 12px 0;
          border-bottom: 1px solid #bdbdbd;
          font-size: 13px;
        }
      }
      .dropdown-menu.show[x-placement="top-start"] {
        border-radius: 20px 20px 3px 3px;
      }
    }
    .show.dropdown .dropdown-toggle {
      svg {
        transform: rotate(91deg);
        transition: transform 0.2s ease-in-out;
      }
    }

    svg {
      color: #CA2D2D;
      font-size: 18px;
    }
  }
}

.contact-program {
  .contact-program-image {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 1rem;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    div {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      img {
        width: 85%;
        height: auto;
      }
    }
  }
  
  .contact-program-label {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    padding: 10px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contact-program.danger {
  .contact-program-label, .contact-program-image div{
    background-color: #CA2D2D;
  }
}

.contact-program.info {
  .contact-program-label, .contact-program-image div {
    background-color: #2d7fca;
  }
}

.list-ops-wrapper {
  margin: 1.5rem 0 3rem 0;
  .list-ops-container {
    .list-ops-item:not(.active) {
      background-color: #c4c4c4;
    }
    .list-ops-item {
      border-radius: 5px;
      background-color: #CA2D2D;
      color: white;
      margin-bottom: 1.5rem;
      .list-ops-body {
        padding: 15px 10px;
        .ops-value {
          font-weight: bold;
          color: white;
          font-size: 25px;
          padding-bottom: 8px;
        }
        .ops-info {
          font-weight: 200;
          font-size: 11px;
          display: flex;
          justify-content: space-between;
          span:first-child {
            max-width: 190px;
            display: block;
            margin-right: 10px;
          }
        }
      }
      .list-ops-footer {
        // filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.25));
        button {
          width: 100%;
          padding: 14px;
          background-color: #ffe500;
          color: black;
          border: none;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          text-transform: capitalize;
          font-weight: 500;
          filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.25));
        }
        button:not(:disabled):hover {
          background-color: #c3b007;
          cursor: pointer;
        }
        button:disabled {
          opacity: 0.6;
          cursor: no-drop;
        }
      }
    }
  }
}

.list-voucher-wrapper {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  .list-voucher-container {
    .voucher-item {
      border-radius: 5px;
      padding: 10px;
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 1rem;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));;

      .voucher-logo {
        background-color: white;
        border-radius: 3px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 117px;
        max-width: 117px;
        min-height: 118px;
        max-height: 118px;
        img {
          width: 75%;
          height: auto;
        }
      }
      
      .voucher-body {
        margin-left: 12px;
        color: white;
        width: 100%;

        .voucher-tilte {
          border-bottom: 1.5px solid white;
          font-weight: normal;
          font-size: 16px;
          margin-bottom: 5px;
        }
        .voucher-value {
          font-size: 15px;
          font-weight: normal;
        }

        .voucher-expired {
          font-size: 11px;
          font-weight: normal;
          p {
            margin-bottom: 0;
          }
        }

        .voucher-button {
          margin-top: 15px;
        }
      }
    }
    .voucher-info {
      background-color: #2D7FCA;
    }
    .voucher-success {
      background: rgba(97, 133, 50, 0.69);
    }

    .voucher-warning {
      background-color: #FFB904;
      .voucher-body {
        color: black;
        .voucher-tilte {
          border-color: black;
        }
      }
    }

    .voucher-danger {
      background: rgba(223, 18, 1, 0.69)
    }
  }
}

.text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}

.button-blue {
  text-align: center;
  
  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  
  button {
    width: 64px;
    height: 64px;
    background-color: #2D7FCA;
    border-radius: 4px;
    border: none;
    margin-bottom: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 10px;
    img {
      width: 100%;
      height: auto;
    }
  }

  button:hover {
    background-color: #195a97;
  }
}

.floating-button {
  position: sticky;
  bottom: 25px;
  right: 20px;
  width: auto;
  z-index: 10;
  /* left: 10px; */
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 10px;

  .single {
    cursor: pointer;
    background: #CA2D2D;
    z-index: 100;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
}

.floating-button-founder {
  position: fixed;
  bottom: 25px;
  width: auto;
  z-index: 11;
  /* left: 10px; */
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 10px;
  .container-floating {
    background-color: #CA2D2D;
    display: flex;
    padding: 10px;
    color: white;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    .floating-value {
      display: none;
      font-size: 13px;
      line-height: 15px;
    }

    img {
      width: 30px;
      height: auto;
      // margin-left: px;
    }
  }
  .container-floating:hover, .container-floating.show {
    .floating-value {
      animation: fadeIn 0.5s;
      display: block;
    }
    img {
      margin-left: 10px;
    }
  }
}

@keyframes fadeIn {
  from {
       opacity: 0;
  }

  to {
       opacity: 1;
  }
}

.form-item.bg-white {
  padding: 1rem;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.form-item {
  .form-button {
    display: flex;
  }
}

.payment-form {
  background-color: #CA2D2D;
  font-family: 'Open Sans', sans-serif;
  padding: 1rem;
  border-radius: 5px;
  .payement-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #F9F7F7;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      // color: $base-gray;
      padding: 14px 20px;
      margin-bottom: 1.5rem;
      font-family: 'Roboto', sans-serif;
      
      h3 {
          font-size: 16px;
          margin-bottom: 0;
          font-weight: 500;
          font-family: 'Roboto', sans-serif;
      }
      h3:last-child{
          font-weight: 900;
      }

      h6{
        margin-bottom: 10px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
      }
  }

  p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #888888;
      margin-bottom: 1.5rem;
  }

  .info-payment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      img {
          // width: 90px;
          height: auto;
          margin-right: 5px;
      }

      div{
          text-align: right;
          
          span{
              font-family: 'Open Sans', sans-serif;
              display: block;
              // color: $base-gray;
              font-weight: 600;
          }
          span:first-child{
              font-size: 14px;
          }
          span:nth-child(2){
              font-size: 13px;
          }
      }
  }

  .form-button {
      // padding-bottom: 5rem;
  }
}


.list-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  .list-button-item {
    display: flex;
    align-items: center;
    justify-content: center;
    // max-width: 100px;
    flex-wrap: wrap;
    padding: 0 10px;
    flex: 0 0 33.333333%;
    width: 33.333333%;

    margin-bottom: 10px;

    span {
      width: 100%;
      font-size: 12px;
      display: block;
      margin-top: 7px;
      
    }
  }
}

.btn.btn-icon-blue {
  background-color: #2D7FCA;
  padding: 10px;
  width: 50px;
  height: 50px;
   img {
     width: 80%;
     height: auto;
   }
}
