* {
    font-family: 'Open Sans', sans-serif;
}
.welcome-page {
    color: $base-white;
    position: relative;
    width: 100%;
    background: url('../images/home_background.svg') no-repeat;
    background-size: cover;

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        background: $base-white;
    }

    .header img{
        width: 150px;
        height: 33px;
    }

    .welcome-page-body {
        padding: 10rem 2rem 1rem 2rem;
        // margin-top: 5rem;
        margin-bottom: 5rem;

        .welcome-page-title-label {
            margin-top: 25px;
            font-weight: 600;
            font-style: normal;
            font-size: 14px;
        }

        .dipsy-logo{
            margin-top: 17px;
            margin-bottom: 3rem;
        }

        .welcome-page-title {
            font-size: 42px;
            line-height: 1.5;
        }

        .welcome-page-description {
            font-size: 13px;
            font-weight: 600;
            line-height: 16px;
            color: #FFFFFF;
        }
    }

    .welcome-page-title-footer {
        padding: 0 2rem;
        // padding-top: 100px;
        // padding-bottom: 10px;
        // text-align: center;
        .button-footer{
            display: flex;
            button.btn.btn-primary {
                box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                border-radius: 3px;
                padding: 10px 20px;
            }
            button.btn.btn-primary:hover {
                background-color: #e0cc16;
            }
            // align-items: center;
            // justify-content: space-between;
        }
        .form-footer {
            text-align: center;
            margin-bottom: 0;
            padding-bottom: 5rem;
        }

        p {
            font-size: 12px;
            margin-bottom: 0;
            text-align: left;
            margin-left: 15px;
        }
            
        .btn-primary {
            // width: 250px;
            font-size: 12px;
            font-weight: 700;
            line-height: 11.72px;
            border-radius: 2px;
            border: none;
            color: $base-true-black;
            // margin-bottom: .5rem;
            background-color: $base-yellow;
            box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
            -webkit-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
            -moz-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
        }

        .btn-primary:active {
            background-color: $base-yellow-hover;
        }

        .btn-link {
            font-size: 16px;
            padding: 0;
            color: $base-yellow;
        }

        form {
            text-align: left;
            // margin-bottom: 20px;
            .form-label {
                font-size: 13px;
            }
            .invalid-feedback{
                color: $base-white;
            }
            .form-control.is-invalid, .was-validated .form-control:invalid{
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fff' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fff' stroke='none'/%3E%3C/svg%3E");
            }
            input {
                background-color: transparent;
                height: 34px;
                font-size: 13px;
                border-radius: 3px;
                color: $base-white;
                border-color: $base-white;
            }

            input:focus, input:hover, input::placeholder {
                color: $base-white;
                font-family: 'Open Sans', sans-serif;
            }

            input.visited {
                color: $base-white;
            }
        }

        .form-check {
            display: flex;
            align-items: center;
            input {
                width: 1em;
                height: 1em;
                margin-top: 0.25em;
                vertical-align: top;
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                border: 1px solid #f2f2f2;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                -webkit-print-color-adjust: exact;
                border-radius: unset;
            }
            input:checked[type=checkbox] {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
            }
            input:checked {
                background-color: #0d6efd;
                border-color: #0d6efd;
            }
            label{
                font-size: 13px;
            }
        }

        .forgot-password-link {
            // width: 100%;
            text-align: right;
            color: $base-white;
            font-size: 13px;

            &:hover {
                color: $base-white;
            }
        }
    }

    @media only screen and (max-width: $base-width-md) {
        .welcome-page-body {
            padding: 5rem 2rem 1rem 2rem;
            // margin-top: 2rem;
        }
    }

    @media only screen and (max-width: $base-width-sm) {
        .welcome-page-body {
            padding: 5rem 0rem 1rem 0rem;
            // margin-top: 2rem;
            // margin-bottom: 2rem;

            .welcome-page-title {
                font-size: 28px;
            }

            .welcome-page-description {
                font-size: 14px;
            }
        }
        
        .welcome-page-title-footer {
            padding: 0;
        }
    }

    @media only screen and (max-width: $base-width-super-sm) {
        .welcome-page-title-footer {
            margin-top: 50px;
            padding-bottom: 10px;
        }
    }
}

.registration-page {
    font-family: 'Roboto', sans-serif;
    header {
        width: 100%;
        min-height: 218px;
        background: url('../images/header_image.png') no-repeat;
        background-size: 100% auto;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 15px 25px;

        h1 {
            font-size: 24px;
            font-family: 'Roboto', sans-serif;
            color: $base-true-black;
            // padding: 10px;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .form-group{
        position: relative;
        .form-label{
            color: $base-gray;
            font-weight: bold;
            font-size: 16px;
        }
        .form-control{
            border: 2px solid #C4C4C4;
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 600;
            // color: #C0C0C0;
        }

        .icon-form {
            position: absolute;
            right: 10px;
            top: 36px;
        }

        .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #C0C0C0;
            opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #C0C0C0;
        }

        .form-control::-ms-input-placeholder { /* Microsoft Edge */
            color: #C0C0C0;
        }
        .form-control.is-invalid, .form-control:invalid{
            border-color: #dc3545;
        }
    }

    .registration-form {
        .alert {
            font-size: 12px;
            color: $base-white;
            background-color: $base-red;
        }

        .alert.alert-danger{
            background: rgba(223, 18, 1, 0.69);
            font-family: 'Roboto', sans-serif;
            padding-left: 35px;
            // box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

            span:first-child{
                font-weight: 700;
                font-size: 15px;
                margin-bottom: 5px;
                display: inline-block;
            }
            span:last-child{
                font-size: 13px;
            }
        }

        .form-footer {
            text-align: center;

            .btn-primary {
                // width: 250px;
                font-size: 14px;
                font-weight: 700;
                line-height: 11.72px;
                border-radius: 3px;
                padding: 7px 12px;
                border: none;
                color: $base-true-black;
                // margin-bottom: .5rem;
                background-color: $base-yellow;
                box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                -webkit-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                -moz-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                width: 100%;
                padding: 14px;
                font-size: 17px;
            }
    
            .btn-primary:active {
                background-color: $base-yellow-hover;
            }

            .btn-primary:hover{
                background-color: $base-yellow-hover;
                color: $base-true-black;
            }
        }
    }

    .custom-card {
        // background: #FFFFFF;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        font-family: 'Poppins-Regular';
        input, .select2-selection, textarea {
            background-color: transparent!important;
        }
    }

    @media only screen and (max-width: $base-width-sm) {
        header {
            min-height: 180px;
        }
    }
}

.googleContainer {
    // margin-bottom: 15px;
    text-align: center;
    margin-top: 5px;
    font-size: 12px;

    .label-text {
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;

        span{
            display: inline-block;
            width: 50%;
            height: 1px;
            background: #cccccc7d;
        }
        div{
            margin: 0 10px;
        }
    }

    .btn-google {
        // padding: 10px;
        background: white;
        font-weight: bold;
        color: $base-true-black;
        border-radius: 5px;
        border-width: 1.8px;
        border-color: unset;
        font-size: 15px;
        border-radius: 25px;
        padding: 10px 20px;
        box-shadow: 0px 9px 3px -9px rgba(0,0,0,0.76);
        -webkit-box-shadow: 0px 9px 3px -9px rgba(0,0,0,0.76);
        -moz-box-shadow: 0px 9px 3px -9px rgba(0,0,0,0.76);
        img{
            width: 28px;
            margin-right: 10px;
        }
    }

    .btn-google:disabled{
        opacity: 0.5;
    }

    .btn-google:hover:not(:disabled){
        background-color: #f1f1f1;
    }
}

.select2-container--bootstrap4 {
    .select2-selection--single {
        border: 2px solid #C4C4C4!important;
        height: 35px !important;
        .select2-selection__rendered {
            font-size: 14px;
            font-weight: 700;
            padding: 0px 20px!important;
            .select2-selection__placeholder {
                color: #C0C0C0;
                font-family: 'Open Sans', sans-serif;
            }
        }
    }
    .select2-results {
        .select2-results__options {
            .select2-results__option {
                padding-right:20px!important;
                padding-left:20px!important;
            }
            .select2-results__option--highlighted, .select2-results__option--highlighted.select2-results__option[aria-selected="true"]{
                color: #fff;
                background-color: #CA2D2D!important;
            }
        }
    } 
}

.select2WithSearch {
    display: none!important;
}

select.is-invalid ~ .select2-container--bootstrap4 .select2-selection, form.was-validated select:invalid ~ .select2-container--bootstrap4 .select2-selection{
    border-color: #dc3545!important;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow{
    right: 10px!important;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
    border-color: unset!important;
    border-width: 0!important;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b::after{
    content: '';
    width: 12px;
    height: 9px;
    display: block;
    background: url('../images/icon/caret_down.svg') no-repeat;
    background-size: cover;
}

.select2WithoutSearch {
    display: none!important;
}